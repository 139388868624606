import {ChangeDetectorRef, Component, OnInit, ViewRef} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {OrderByPipe} from "../../pipe/OrderByPipe";
import {Reservation} from "../../models/reservation.interface";
import {Design} from "../../models/design.interface";
import {Seating} from "../../models/seating.interface";

declare var $: any;

@Component({
  templateUrl: './seating.html',
  styleUrls: ['./seating.scss']
})
export class SeatingComponent implements OnInit {
  private readonly reservationId: string;
  private readonly setDesign: string;

  private reservation: Reservation;
  public design: Design;
  public seating: Seating;

  public showDesignless: boolean;
  public showDesign: boolean;
  public cssSize: string;
  public errorCode: number;
  public lastSuccessfulClickedRow: number = -1;
  public lastSuccessfulClickedPositions = [];
  public lastSuccessfulClickedSeat: number = -1;

  public loading: boolean = true;

  private static instance: any;

  constructor(private http: HttpClient, private route: ActivatedRoute, private chRef: ChangeDetectorRef){
    document.domain = this.route.snapshot.params.domain || 'vipbutik.dk';
    this.reservationId = this.route.snapshot.params.reservationId;
    this.setDesign = this.route.snapshot.params.design;
    SeatingComponent.instance = this;
  }

  ngOnInit(): void {
    this.getReservation();
  }

  private getReservation(): void {
    const url = '/api/v1/seeding/get_reservation/' + this.reservationId;
    this.http.get(url).subscribe((data) => {
      this.reservation = data as Reservation;
      this.getSeatingDesign();
    });
  }

  private getSeatingDesign() {
    const url = '/api/v1/seedingdesign/design/' + this.reservation.product_id;
    this.http.get(url).subscribe((data) =>{
      this.design = data as Design;
      if(this.setDesign == 'false'){
        this.showDesignless = false;
        this.showDesign = false;
      }
      else{
        this.showDesign = !!this.design.movie_schedule || !!this.design.movie_name || !!this.design.movie_picture_url || !!this.design.left_header_picture_url || !!this.design.right_header_picture_link;
        this.showDesignless = !this.showDesign;
      }
      this.getSeats();
    });
  }

  private getSeats(callbackData=null, callback=null) {
    const url = '/api/v1/seeding/seats/'+this.reservation.product_id+'/reservation_id/'+this.reservationId;
    this.http.get(url).subscribe((data)=>{
      this.seating = data as Seating;
      this.loading = false;
      let selectedRow = this.seating.selected_row;
      let selectedSeat = this.seating.selected_seat;
      if(selectedRow && selectedSeat){
        this.checkRules(selectedRow, selectedSeat, null);
      }

      let largestRow = this.getLargestNoneVipRow(this.seating.product_seeding.rows);
      this.setSizeOfView(largestRow);

      // Set the none available seats as reserved / bought
      this.setUnavailableSeats();


      if (this.chRef && !(this.chRef as ViewRef).destroyed) {
        // There might need some changes here (look at main.js)
        this.chRef.detectChanges();
        this.alignContent();
      }

      if(callback && callbackData){
        console.log('calling callback');
        callback(callbackData);
      }
    });
  }

  private alignContent(){
    let rows = $('.row-content');
    let largestRowWidth = this.getLargestWidthRow(rows);

    // Loop all seat and figure out how much all non VIP is in width
    // Divide it with the number of VIP seats on that row, and set the width to it
    for(let i=0; i<this.seating.product_seeding.rows.length; i++){
      let vipPosition = [];
      // Add all the vip position in the row in vipPosition as array
      for(let j=0; j<this.seating.product_seeding.rows[i].seats.length;j++){
        if(this.seating.product_seeding.rows[i].seats[j].type==='VIP'){
          vipPosition.push(j);
        }
      }

      if(vipPosition.length === 0){
        continue;
      }

      // Get the width of all none VIP elements in the same row (i is the same in eventplace.rows and in rows)
      let rowWidth = 0;

      if(rows[i] && rows[i].children && rows[i].children.length) {
        for (let x = 0; x < rows[i].children.length; x++) {
          if ($.inArray(x, vipPosition) === -1) {
            rowWidth += rows[i].children[x].offsetWidth + 2;
          }
        }
      }

      // Setting the VIP seats correct width
      for(let y=0;y<vipPosition.length;y++){
        let newWidth= Math.round((largestRowWidth-rowWidth)/vipPosition.length)-2;
        if(rows[i] && rows[i].children && rows[i].children.length) {
          rows[i].children[vipPosition[y]].style.width = newWidth + 'px';
        }
      }
    }
  }

  private getLargestWidthRow(rows){
    let largestRowWidth = -1;

    for(let i=0;i<rows.length;i++){
      let rowWidth = -1;
      for(let j=0;j<rows[i].children.length;j++){
        // check if there is a vip seat in this row, and reloop if there is
        if(this.seating.product_seeding.rows[i].seats[j].type==='VIP'){
          continue;
        }
        if(this.seating.product_seeding.rows[i].seats[j].type==='Standard'){
          rowWidth +=rows[i].children[j].offsetWidth+2;
        }
        else{
          rowWidth +=rows[i].children[j].offsetWidth;
        }
      }
      if(largestRowWidth < rowWidth){
        largestRowWidth = rowWidth;
      }
    }
    return largestRowWidth;
  }

  private getLargestNoneVipRow(rows){
    let largestRow = 0;
    for(let i=0;i < rows.length; i++){
      if(largestRow < rows[i].seats.length){
        largestRow = rows[i].seats.length;
      }
    }
    return largestRow;
  }

  private setSizeOfView(largestRow){
    if(largestRow > 32){
      this.cssSize = 'xx-small';
    }
    else if(largestRow > 28){
      this.cssSize = 'x-small';
    }
    else if(largestRow > 20){
      this.cssSize = 'small';
    }
    else if(largestRow > 10){
      this.cssSize = 'medium';
    }
    else{
      this.cssSize = 'large';
    }
  }

  private setUnavailableSeats(){
    for(let i=0; i < this.seating.product_seeding.rows.length; i++){
      var all_reserved_for_others = this.seating.product_seeding.rows[i].reserved_for_others;
      for(var j=0; j < this.seating.product_seeding.rows[i].seats.length; j++){
        if(all_reserved_for_others && this.seating.product_seeding.rows[i].seats[j].type !== 'Space'){
          this.seating.product_seeding.rows[i].seats[j].boughtClass = 'bought';
          continue;
        }

        if(this.seating.product_seeding.rows[i].seats[j].bought){
          this.seating.product_seeding.rows[i].seats[j].boughtClass = 'bought';
        }
        if(this.seating.product_seeding.rows[i].seats[j].is_reserved){
          this.seating.product_seeding.rows[i].seats[j].reservedClass = 'reserved';
          var reservationDate = new Date(this.seating.product_seeding.rows[i].seats[j].reserved);
          reservationDate.setTime(reservationDate.getTime() + (1*60*60*1000));
          this.seating.product_seeding.rows[i].seats[j].reservedEndingDate = reservationDate;
        }
      }
    }
  }

  private checkRules(row_position, seat_position, type){
    if(type && type == 'Space'){
      return;
    }

    this.loading = true;

    let payload = {
      'product_seeding_id': this.seating.product_seeding._id,
      'row_position': row_position,
      'seat_position': seat_position,
      'number_of_seats': this.reservation.number_of_seats
    };

    const url = '/api/v1/seeding/rules';
    this.http.post(url, payload).subscribe((data)=>{
      if(!data['valid']){
        this.lastSuccessfulClickedRow =-1;
        this.lastSuccessfulClickedPositions = [];
        this.lastSuccessfulClickedSeat = -1;
      }

      this.getSeats(data, this.showReservationOrError);
    }, ()=>{
      this.errorCode = 1;
      this.lastSuccessfulClickedRow =-1;
      this.lastSuccessfulClickedPositions = [];
      this.lastSuccessfulClickedSeat = -1;
      this.getSeats();
    });
  }

  private showReservationOrError(response){
    if(response['valid']){
      SeatingComponent.instance.errorCode = -1;

      // Reset selected
      for(let i=0; i <SeatingComponent.instance.seating.product_seeding.rows.length;i++){
        for(let j=0; j < SeatingComponent.instance.seating.product_seeding.rows[i].seats.length;j++){
          SeatingComponent.instance.seating.product_seeding.rows[i].seats[j]['class'] = '';
        }
      }

      SeatingComponent.instance.lastSuccessfulClickedRow = response['row'];
      SeatingComponent.instance.lastSuccessfulClickedPositions = response['seat_positions'];

      let foundSeat = false;
      // Set selected
      for(let i=0; i <SeatingComponent.instance.seating.product_seeding.rows.length;i++){
        if (SeatingComponent.instance.seating.product_seeding.rows[i].position === response.row){
          for(let x=0; x < SeatingComponent.instance.seating.product_seeding.rows[i].seats.length;x++){
            for(let h=0; h < response.seat_positions.length; h++){
              if(SeatingComponent.instance.seating.product_seeding.rows[i].seats[x].position === response.seat_positions[h].position){
                SeatingComponent.instance.seating.product_seeding.rows[i].seats[x]['class'] = 'selected-seat';

                if(!foundSeat){
                  foundSeat = true;
                  SeatingComponent.instance.lastSuccessfulClickedSeat = response.seat_positions[h].position;
                }
              }
            }
          }
        }
      }
    }
    else{
      SeatingComponent.instance.errorCode = response['error_code'];
    }
  }

  public reserveSeats(){
    if(this.lastSuccessfulClickedRow === -1 || this.lastSuccessfulClickedSeat === -1 || this.lastSuccessfulClickedPositions.length === 0){
      return;
    }

    let payload = {
      'product_seeding_id': this.seating.product_seeding._id,
      'row_position': this.lastSuccessfulClickedRow,
      'seat_position': this.lastSuccessfulClickedSeat,
      'number_of_seats': this.reservation.number_of_seats,
      'reservation_id': this.reservationId,
      'selected_seats': this.lastSuccessfulClickedPositions
    };

    const url = '/api/v1/seeding/reserve_seats';

    this.http.post(url, payload).subscribe( (data)=>{
      if (data['success']){
        window.parent['seatIsReserved'](data['orders']);
      }
      else{
        this.errorCode = data['error_code'];
        this.getSeats();
      }
    }, ()=>{
      this.errorCode = 1;
      this.getSeats();
    });
  }

  public cancelSeating(){
    window.parent['cancleReservation']();
  }

  public helper(row, seat, show){
    if(seat.type === 'Space'){
      return;
    }
    let element = $('#helper-'+row.position+'-'+seat.position);
    if(show){
      element.show();
    }
    else{
      element.hide();
    }
  }
}
