import {ChangeDetectorRef, Component, ViewRef} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {Seating} from "../../models/seating.interface";
import {Design} from "../../models/design.interface";

declare var $: any;

@Component({
  templateUrl: './preview.html',
  styleUrls: ['./preview.scss']
})
export class PreviewComponent{
  public loading = true;
  public showDesign = false;
  public showDesignless = false;

  public numberOfSeats = 0;
  public numberOfSeatsReserved = 0;
  public numberOfSeatsBought = 0;
  public numberOfSeatsRedeemed = 0;
  private productId: string;

  public cssSize: string;

  public design: Design;

  // the seats of the seeding
  public seating: Seating = null;

  constructor(private http: HttpClient, private route: ActivatedRoute, private chRef: ChangeDetectorRef){
    document.domain = this.route.snapshot.params.domain || 'vipbutik.dk';
    this.productId = this.route.snapshot.params.productId;

    this.getDesign();
    this.getPreviewSeats();
  }

  private getDesign(){
    const url = '/api/v1/seedingdesign/design/' + this.productId;
    this.http.get(url).subscribe((data)=>{
      this.design = data as Design;
      this.showDesign = this.design.left_header_picture_url !== null && this.design.right_header_picture_link !== null;
      this.showDesignless = !this.showDesign;
    });
  }

  private getPreviewSeats(callbackData=null, callback=null){
    const url = '/api/v1/seeding/seats/' + this.productId;

    this.http.get(url).subscribe((data)=>{
      this.seating = data as Seating;

      let largestRow = this.getLargestNoneVipRow(this.seating.product_seeding.rows);
      this.setSizeOfView(largestRow);

      // Set the none available seats as reserved / bought
      this.setUnavilableSeats();

      if (this.chRef && !(this.chRef as ViewRef).destroyed) {
        this.alignContent();
        this.chRef.detectChanges();
      }

      if(callback && callbackData){
        callback(callbackData);
      }
      this.getStatistics();

      this.loading = false;
    });

  }

  private alignContent(){
    let rows = $('.row-content');
    let largestRowWidth = this.getLargestWidthRow(rows);

    // Loop all seat and figure out how much all non VIP is in width
    // Divide it with the number of VIP seats on that row, and set the width to it
    for(let i=0; i<this.seating.product_seeding.rows.length; i++){
      let vipPosition = [];
      // Add all the vip position in the row in vipPosition as array
      for(let j=0; j<this.seating.product_seeding.rows[i].seats.length;j++){
        if(this.seating.product_seeding.rows[i].seats[j].type==='VIP'){
          vipPosition.push(j);
        }
      }

      if(vipPosition.length === 0){
        continue;
      }

      // Get the width of all none VIP elements in the same row (i is the same in eventplace.rows and in rows)
      let rowWidth = 0;
      if(rows[i] && rows[i].children && rows[i].children.length){
        for(let x=0;x<rows[i].children.length;x++){
          if($.inArray(x, vipPosition)===-1){
            rowWidth +=rows[i].children[x].offsetWidth +2;
          }
        }
      }

      // Setting the VIP seats correct width
      for(let y=0;y<vipPosition.length;y++){
        let newWidth= Math.round((largestRowWidth-rowWidth)/vipPosition.length)-2;
        if(rows[i] && rows[i].children && rows[i].children.length) {
          rows[i].children[vipPosition[y]].style.width = newWidth + 'px';
        }
      }
    }
  }

  private getLargestWidthRow(rows){
    let largestRowWidth = -1;

    for(let i=0;i<rows.length;i++){
      let rowWidth = -1;
      for(let j=0;j<rows[i].children.length;j++){

        // check if there is a vip seat in this row, and reloop if there is
        if(this.seating.product_seeding.rows[i].seats[j].type==='VIP'){
          continue;
        }
        if(this.seating.product_seeding.rows[i].seats[j].type==='Standard'){
          rowWidth +=rows[i].children[j].offsetWidth+2;
        }
        else{
          rowWidth +=rows[i].children[j].offsetWidth;
        }
      }
      if(largestRowWidth < rowWidth){
        largestRowWidth = rowWidth;
      }
    }
    return largestRowWidth;
  }

  private getLargestNoneVipRow(rows){
    let largestRow = 0;
    for(let i=0;i < rows.length; i++){
      if(largestRow < rows[i].seats.length){
        largestRow = rows[i].seats.length;
      }
    }
    return largestRow;
  }

  private setSizeOfView(largestRow){
    if(largestRow > 32){
      this.cssSize = 'xx-small';
    }
    else if(largestRow > 28){
      this.cssSize = 'x-small';
    }
    else if(largestRow > 20){
      this.cssSize = 'small';
    }
    else if(largestRow > 10){
      this.cssSize = 'medium';
    }
    else{
      this.cssSize = 'large';
    }
  }

  private setUnavilableSeats(){
    for(let i=0; i < this.seating.product_seeding.rows.length; i++){
      let all_reserved_for_others = this.seating.product_seeding.rows[i].reserved_for_others;
      for(let j=0; j < this.seating.product_seeding.rows[i].seats.length; j++){
        if(all_reserved_for_others && this.seating.product_seeding.rows[i].seats[j].type !== 'Space'){
          this.seating.product_seeding.rows[i].seats[j].boughtClass = 'reserved-for-others';
          continue;
        }

        if(this.seating.product_seeding.rows[i].seats[j]['redeem_date']){
          this.seating.product_seeding.rows[i].seats[j].reservedClass = 'redeemed';
        }
        else if(this.seating.product_seeding.rows[i].seats[j].bought){
          this.seating.product_seeding.rows[i].seats[j].boughtClass = 'bought';
        }
        else if(this.seating.product_seeding.rows[i].seats[j].is_reserved){
          this.seating.product_seeding.rows[i].seats[j].reservedClass = 'reserved';
          let reservationDate = new Date(this.seating.product_seeding.rows[i].seats[j].reserved);
          reservationDate.setTime(reservationDate.getTime() + (1*60*60*1000));
          this.seating.product_seeding.rows[i].seats[j].reservedEndingDate = reservationDate;
        }
      }
    }
  }

  private getStatistics(){
    this.numberOfSeats = 0;
    this.numberOfSeatsReserved = 0;
    this.numberOfSeatsBought = 0;
    this.numberOfSeatsRedeemed = 0;

    for(var i=0;i<this.seating.product_seeding.rows.length; i++){
      for(var j=0;j<this.seating.product_seeding.rows[i].seats.length; j++){
        if(this.seating.product_seeding.rows[i].seats[j].type !== 'Space'){
          this.numberOfSeats++;
        }

        if(this.seating.product_seeding.rows[i].seats[j]['redeem_date']){
          this.numberOfSeatsRedeemed++;
        }

        if(this.seating.product_seeding.rows[i].seats[j].bought){
          this.numberOfSeatsBought++;
        }
        else if(this.seating.product_seeding.rows[i].seats[j].is_reserved){
          this.numberOfSeatsReserved++;
        }
      }
    }
  }

  public helper(row, seat, show){
    if(seat['type'] === 'Space'){
      return;
    }
    let element = $('#helper-'+row['position']+'-'+seat['position']);
    if(show){
      element.show();
    }
    else{
      element.hide();
    }
  }
}
