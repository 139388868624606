<div id="container" class="cm_container">
    <span *ngIf="showDesign">
        <div id="cm_bannercontainer" *ngIf="design.left_header_picture_url || design.right_header_picture_url">
            <div id="cm_logo"><span *ngIf="design.left_header_picture_url"><img src="{{design.left_header_picture_url}}" alt="" /></span></div>
            <div class="cm_seated"> <span class="helper"></span>
                <span *ngIf="design.right_header_picture_link && design.right_header_picture_url"><a href="{{design.right_header_picture_link}}"><img src="{{design.right_header_picture_url}}" alt="" /></a></span>
                <span *ngIf="!design.right_header_picture_link && design.right_header_picture_url"><img src="{{design.right_header_picture_url}}" alt="" /></span>
            </div>
            <div class="spacer"></div>
        </div>

        <div id="cm_sidebar">
            <div id="cm_description">
                <h1 *ngIf="design.movie_name">{{design.movie_name}}</h1>
                <p *ngIf="design.movie_schedule">{{design.movie_schedule}}</p>
            </div>
            <div id="poster"><span *ngIf="design.movie_picture_url"><img src="{{design.movie_picture_url}}" width="221px" alt="" /></span></div>
            <div class="cm_banner">
                <span *ngIf="design.bottom_banner_link && design.bottom_banner_url"><a href="{{design.bottom_banner_link}}"><img src="{{design.bottom_banner_url}}" alt="" /></a></span>
                <span *ngIf="!design.bottom_banner_link && design.bottom_banner_url"><a href="{{design.bottom_banner_link}}"><img src="{{design.bottom_banner_url}}" alt="" /></a></span>
            </div>
        </div>
    </span>
  <div id="cm_cinemacontainer" *ngIf="seating">
    <div id="cm_screen">{{seating.product_seeding.event_place_name}}<span>Lærred</span></div>
    <div class="seat-helper-text"></div>
    <div class="seats-content {{cssSize}}">

      <div *ngIf="loading">
        <img src="assets/images/loading.gif" width="50%" />
      </div>

      <!--
      <div *ngIf="!loading" class="row-content" ng-repeat="row in seeding.rows | orderBy:'position'">
        <div *ngIf="seat.seat_position!='right'" class="{{ seat.type | lowercase}} {{seat.class}}  {{seat.reservedClass}} {{seat.boughtClass}}" (mouseover)="helper(row, seat, true)" (mouseout)="helper(row, seat, false)" ng-repeat="seat in row.seats | orderBy:'position'">
          &nbsp;
          <div id="helper-{{row.position}}-{{seat.position}}" class="helper-preview-box">
            <div>Row: {{row.name}}</div>
            <div *ngIf="seat.type == 'Sofa'">Seat: {{seat.number}}, {{row.seats[$index+1].number}}</div>
            <div *ngIf="seat.type != 'Sofa'">Seat: {{seat.number}}</div>
            <div>Type: {{seat.type}}</div>
            <div *ngIf="seat.reservedEndingDate">Runs out: {{seat.reservedEndingDate | date:'HH:mm'}}</div>
          </div>
        </div>
      </div>
      -->

      <div *ngIf="!loading">
        <div class="row-content" *ngFor="let row of seating.product_seeding.rows | orderBy:'position'">

          <div *ngFor="let seat of row['seats'] | orderBy:'position' ;let indexOfelement=index;" [ngClass]="{'sofa-right-position': seat['seat_position'] == 'right'}"  class="{{ seat['type'] | lowercase}} {{seat['class']}} {{seat['reservedClass']}} {{seat['boughtClass']}} {{seat['social_distance'] ? 'social-distance' : ''}}" (mouseover)="helper(row, seat, true)" (mouseout)="helper(row, seat, false)" >             &nbsp;
            <!--
          <div *ngFor="let seat of row['seats'] | orderBy:'position' ;let indexOfelement=index;">
            <div *ngIf="seat['seat_position']!='right'" class="{{ seat['type'] | lowercase}} {{seat['class']}} {{seat['reservedClass']}} {{seat['boughtClass']}} {{seat['social_distance'] ? 'social-distance' : ''}}" (mouseover)="helper(row, seat, true)" (mouseout)="helper(row, seat, false)">
          -->              &nbsp;

              <div *ngIf="seat['reservedEndingDate'] && !seat['bought']" id="helper-{{row['position']}}-{{seat['position']}}" class="helper-box">
                <div>Row: {{row['name']}}</div>
                <div *ngIf="seat['type'] == 'Sofa'">Seat: {{seat['number']}}, {{row['seats'][indexOfelement+1].number}}</div>
                <div *ngIf="seat['type'] != 'Sofa'">Seat: {{seat['number']}}</div>
                <div>Type: {{seat['type']}}</div>
                <div *ngIf="seat['reservedEndingDate']">Runs out: {{seat['reservedEndingDate'] | date:'HH:mm'}}</div>
              </div>

          </div>

        </div>
      </div>

    </div>

    <div id="colorcontainer">
      <div id="colorinnercontainer">
        <div id="roed">&nbsp;</div>
        <div class="colorlabel">Købt</div>

        <div id="dark-red">&nbsp;</div>
        <div class="colorlabel">Reserveret til andre</div>

        <div id="yellow">&nbsp;</div>
        <div class="colorlabel">Reserveret</div>

        <div id="groen">&nbsp;</div>
        <div class="colorlabel">Ledige</div>

        <div id="blaa">&nbsp;</div>
        <div class="colorlabel">Indløste</div>

        <div id="graa">&nbsp;</div>
        <div class="colorlabel">VIP område</div>

        <div class="spacer">&nbsp;</div>

        <div class="statisticlabel">Antal pladser ialt:</div>
        <div class="statisticlabel">{{numberOfSeats}}</div>
        <div class="spacer">&nbsp;</div>

        <div class="statisticlabel">Antal reserveret pladser:</div>
        <div class="statisticlabel">{{numberOfSeatsReserved}}</div>
        <div class="spacer">&nbsp;</div>

        <div class="statisticlabel">Antal solgte pladser:</div>
        <div class="statisticlabel">{{numberOfSeatsBought}}</div>
        <div class="spacer">&nbsp;</div>

        <div class="statisticlabel">Antal indløste pladser:</div>
        <div class="statisticlabel">{{numberOfSeatsRedeemed}}</div>
        <div class="spacer">&nbsp;</div>
      </div>
    </div>
  </div>
</div>
<br />
