import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SeatingComponent} from './component/seating/seating.component';
import {PreviewComponent} from './component/preview/preview.component';

const routes: Routes = [
  { path: 'preview/:productId/:domain', component: PreviewComponent},
  { path: 'preview/:productId', component: PreviewComponent},
  { path: ':reservationId', component: SeatingComponent},
  { path: ':reservationId/:domain', component: SeatingComponent},
  { path: ':reservationId/:domain/:design', component: SeatingComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
