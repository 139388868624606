<div id="container" style="min-height: 400px;" [ngClass]="{'cm_container': showDesign, 'cm_container_no_design': !showDesign}">
    <span *ngIf="showDesign">
        <div id="cm_bannercontainer" *ngIf="design.left_header_picture_url || design.right_header_picture_url">
            <div id="cm_logo"><span *ngIf="design.left_header_picture_url"><img src="{{design.left_header_picture_url}}" alt="" /></span></div>
            <div class="cm_seated"> <span class="helper"></span>
                <span *ngIf="design.right_header_picture_link && design.right_header_picture_url"><a target="_blank" href="{{design.right_header_picture_link}}"><img src="{{design.right_header_picture_url}}" alt="" /></a></span>
                <span *ngIf="!design.right_header_picture_link && design.right_header_picture_url"><img src="{{design.right_header_picture_url}}" alt="" /></span>
            </div>
            <div class="spacer"></div>
        </div>

        <div id="cm_sidebar">
            <div id="cm_description">
                <h1 *ngIf="design.movie_name">{{design.movie_name}}</h1>
                <p *ngIf="design.movie_schedule">{{design.movie_schedule}}</p>
            </div>
            <div id="poster"><span *ngIf="design.movie_picture_url"><img src="{{design.movie_picture_url}}" width="221px" alt="" /></span></div>
            <div class="cm_banner">
                <span *ngIf="design.bottom_banner_link && design.bottom_banner_url"><a target="_blank" href="{{design.bottom_banner_link}}"><img src="{{design.bottom_banner_url}}" alt="" /></a></span>
                <span *ngIf="!design.bottom_banner_link && design.bottom_banner_url"><a target="_blank" href="{{design.bottom_banner_link}}"><img src="{{design.bottom_banner_url}}" alt="" /></a></span>
            </div>
        </div>
    </span>
  <div [ngClass]="{'cm_cinemacontainer': showDesign}" *ngIf="seating">
    <div id="cm_screen">{{seating.product_seeding.event_place_name}}<span>Lærred</span></div>
    <div class="seat-helper-text">Klik på de ledige pladser (grønne) for at vælge dine sæder</div>
    <!--<div id="cm_floor"></div>-->

    <div class="seats-content {{cssSize}}">

      <div *ngIf="loading">
        <img src="assets/images/loading.gif" width="50%" />
      </div>

      <div *ngIf="!loading">
        <div class="row-content" *ngFor="let row of seating.product_seeding.rows | orderBy:'position'">

          <!-- *ngIf="seat['seat_position']!='right'" -->
          <div *ngFor="let seat of row['seats'] | orderBy:'position'" [ngClass]="{'sofa-right-position': seat['seat_position'] == 'right'}"  class="{{ seat['type'] | lowercase}} {{seat['class']}} {{seat['reservedClass']}} {{seat['boughtClass']}} {{seat['social_distance'] ? 'social-distance' : ''}}" (mouseover)="helper(row, seat, true)" (mouseout)="helper(row, seat, false)" (click)="checkRules(row['position'], seat['position'], seat['type'])">             &nbsp;

              <div *ngIf="seat['reservedEndingDate'] && !seat['bought']" id="helper-{{row['position']}}-{{seat['position']}}" class="helper-box">
                <div>Reservationen udløber kl. {{seat['reservedEndingDate'] | date:'HH:mm'}}</div>
              </div>

            </div>

        </div>
      </div>


    </div>


    <div class="tickets">
      <a class="reserve-button" (click)="reserveSeats()" >Reserver</a>
      <a class="cancel-button" (click)="cancelSeating()"  >Annuller</a>
    </div>

    <div id="colorcontainer">
      <div id="colorinnercontainer">
        <div id="roed">&nbsp;</div>
        <div class="colorlabel">Købte</div>

        <div id="yellow">&nbsp;</div>
        <div class="colorlabel">Reserverede</div>

        <div id="groen">&nbsp;</div>
        <div class="colorlabel">Ledige</div>

        <div id="blaa">&nbsp;</div>
        <div class="colorlabel">Dine pladser</div>

        <div id="graa">&nbsp;</div>
        <div class="colorlabel">VIP område</div>

        <!-- <div id="social-distance">&nbsp;</div>
        <div class="colorlabel">Social distance</div> -->

        <div class="spacer">&nbsp;</div>
      </div>
    </div>

    <div *ngIf="errorCode == 1" id="error">Der er desværre sket en fejl i systemet. Du kan eventuelt skrive til hotline@vipbutik.dk for yderligere information.</div>
    <div *ngIf="errorCode == 1000" id="error">Der er desværre ikke nok sæder ledige i den sektion</div>
    <div *ngIf="errorCode == 1001" id="error">En anden har reserveret denne plads</div>
    <div *ngIf="errorCode == 1002" id="error">Denne plads kan desværre ikke bestilles af tekniske årsager. Bestil venligst en anden plads, eller vend tilbage senere</div>
    <div *ngIf="errorCode == 2000" id="error">Man kan ikke vælge sofa sæder med et ulige antal pladser</div>
    <div *ngIf="errorCode == 2001" id="error">Systemet kan ikke finde ledige pladser i denne række</div>
    <div *ngIf="errorCode == 3001" id="error">Systemet kan ikke finde ledige pladser i denne række</div>
    <div *ngIf="errorCode == 4000" id="error">Der er desværre sket en fejl i systemet. Du kan eventuelt skrive til hotline@vipbutik.dk for yderligere information. </div>
    <div *ngIf="errorCode == 5000" id="error">Systemet kan ikke finde ledige pladser i denne række</div>
    <div *ngIf="errorCode == 6000" id="error">En anden har reserveret denne plads</div>
  </div>
</div>
